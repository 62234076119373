$line-color: #474744;

.concertDataContainer {
  margin: 5px;
  padding: 10px;
  color: #FFF;

  h3, h5, h4 {
    color: rgb(231, 228, 228);
    text-shadow: 0px 1px 2px rgb(0, 0, 0);
  }

  h3 {
    color: rgb(231, 228, 228);
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
  }

  span {
    color: rgb(231, 228, 228);
  }

  .information {
    border-top: 1px solid $line-color;
    margin: 2px;
    padding: 2px;
    align-items: center;
  }
}

.recommendation-page {
  height: 100%;
}

.recommendation-page-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
  padding-top: 10px;
  box-shadow: 1px 1px 10px rgb(100, 99, 99);
  z-index: 1000;
  Button {
    margin-right: 10px;
  }
}

.justify-between {
  justify-content: space-between;
}

.recommendation-page-body {
  background-color: #000;
  overflow: auto;
  padding: 10px 30px;

  .pie-container {
    transform-origin: top;
    transform: scale(1.1);
    height: fit-content;
  }
}


.summary-container {
  margin: 10px;
  padding: 10px 5px;
  color: #FFF;
  h3, h4 {
    color: #FFF;
  }
  
  .summary {
    padding: 10px 10px;
    background-color: rgb(26, 26, 26);
    margin: 10px 0px;
    border-left: 2px solid rgb(255, 123, 0);
  }

}

.card-container {
  width: 90%;
  margin: auto;
}


// Screen breakpoint max-width 1650px
@media  screen and (min-width: 1651px) {
  .container {
      transform: scale(0.9);
  }
  .recommendation-page-body {
      height: 92vh;
  }
}

@media  screen and (max-width: 1650px) {
    .container {
        transform: scale(0.8);
    }
    .recommendation-page-body {
      height: 91vh;
    }
}

// Screen breakpoint max-width 1450px
@media  screen and (max-width: 1450px) {
  .container {
      transform: scale(0.7);
  }
  .recommendation-page-body {
    height: 91vh;
  }
}

// Screen breakpoint max-width 1350px
@media  screen and (max-width: 1350px) {
  .container {
      transform: scale(0.65);
  }
  .recommendation-page-body {
    height: 92vh;
  }
}

// Screen breakpoint max-width 1250px
@media  screen and (max-width: 1250px) {
  .container {
      transform: scale(0.6);
  }
  .recommendation-page-body {
    height: 92vh;
  }
}

// Screen breakpoint max-width 1150px
@media  screen and (max-width: 1150px) {
  .container {
      transform: scale(0.5);
  }
  .recommendation-page-body {
    height: 90vh;
  }
}

// Screen breakpoint max-width 950px
@media  screen and (max-width: 950px) {
  .container {
      transform: scale(0.9);
  }
  .recommendation-page-body {
    height: 89vh;
  }
}

// Screen breakpoint max-width 850px
@media  screen and (max-width: 850px) {
  .container {
      transform: scale(0.8);
  }
  .recommendation-page-body {
    height: 89vh;
  }
}

// Screen breakpoint max-width 700px
@media  screen and (max-width: 700px) {
  .container {
      transform: scale(0.7);
  }
}