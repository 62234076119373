$font-color: #000;

.card {
  width: 80%;
  transition: all 1s;
  margin-top: 40px;
  * {
      color: $font-color;
  }

  .inner-card {
    position: absolute; 
    bottom: 0px; 
    border-radius: 50px;
    padding: 20px;
    background: rgba(2, 6, 253, 0.514);
    box-shadow: 1px 1px 8px #222;
    width: 95%;
    transition: all 0.3s;
    cursor: pointer;

    .profile-pic {
        width: 30%;
        height: auto;
        border-radius: 50%;
        position: absolute;
        top:-10%;
        left: 10%;
        margin: 0;
        padding: 0;
    }

    .card-heading {
        display: flex;
        justify-content: space-between;

        h3 {
            color: $font-color;
        }

        p{
            color: $font-color;
        }
    }

    .venue {
        padding: 2px 10px;
        display: flex;
        h6 {
            text-align: center;
            font-size: 13px;
        }
        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .affinity {
        background-color: rgba(255, 255, 255, 0.493);
        padding: 10px;
        margin: 6px 0px;
        border-radius: 10px;
        h6 {
            font-size: 12px;
            margin: 0;
            padding: 0;
            color: rgb(0, 0, 0);
        }
        .field {
            margin-left: 15px;
            h5{
                font-size: 14px;
                color: #000958;

                span{
                    font-size: 16px;
                    color: #00030e;
                }
                color: rgb(0, 0, 0);
            }
        }
    }
  }

  .inner-card:hover {
    transform: scale(1.05);
    box-shadow: 1px 4px 8px rgba(71, 71, 71, 0.596);
    background: rgba(2, 6, 253, 0.795);
  }

  .card-button{
    cursor: pointer;
    float: right;
    top: 1%;
    background-color: transparent;
    border: transparent;
}
}

.card-body{
    position:relative;
    width: 100%;
    top: 30px;
    
}

.brand_logo {
    width: 20px;
    height: 20px;
    margin: 10px;
}

.font-color {
    color: $font-color;
}