.app-container {
  background-position: center;
  background-clip: border-box;
  background-attachment: fixed;
  min-height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.layout-container {
    height: 100vh;

    .layout-body {
        height: 90%;
        overflow: auto;
    }
}

.layout-workspace {
    width: 100%;
    padding-bottom: 50px;

    .content-box {
        padding: 10px 20px;
        box-sizing: border-box;
        min-height: 200px;
        color: #fff;
    }

    .summary-container{
        padding: 20px;
        border-radius: 40px 0px;
        color: #cff;
        text-align: center;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.836);
        margin: auto;

        h3 {
            color: #fff;
        }

        p {
            font-family: 'Indie Flower', cursive;
            font-size: large;
        }
    }

    .card-container{
        width: 100%;
        max-width: 800px;
        color: white;
        padding: 0px 20px;
        margin: 0 auto;  
    }
}

.questionsModal {
    box-shadow: 2px 2px 12px rgb(75, 71, 71);
}

.row-flex {
    display: flex;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.text-size-4 {
    font-size: 18px;
}

.text-size-3 {
    font-size: 22px;
}

.width-md {
    max-width: 1200px;
    margin: auto;
}

.scroll {
    overflow-x: auto;
}

.height-100 {
    height: 100%;
}

.width-100 {
    width: 100%;
}

.cursor {
    cursor: pointer;
}

// margins

.m-1 { margin: 4px; }
.m-2 { margin: 8px; }
.m-3 { margin: 12px; }
.m-4 { margin: 16px; }
.m-5 { margin: 20px; }
.m-6 { margin: 24px; }
.m-7 { margin: 28px; }
.m-8 { margin: 32px; }
.m-9 { margin: 36px; }
.m-10 { margin: 45px; }

.mx-1 { margin: 0px 4px; }
.mx-2 { margin: 0px 8px; }
.mx-3 { margin: 0px 12px; }
.mx-4 { margin: 0px 16px; }
.mx-5 { margin: 0px 20px; }
.mx-6 { margin: 0px 24px; }
.mx-7 { margin: 0px 28px; }
.mx-8 { margin: 0px 32px; }
.mx-9 { margin: 0px 36px; }
.mx-10 { margin: 0px 45px; }

.my-1 { margin: 4px 0px; }
.my-2 { margin: 8px 0px; }
// .my-3 { margin: 12px 0px; }
.my-4 { margin: 16px 0px; }
.my-5 { margin: 20px 0px; }
.my-6 { margin: 24px 0px; }
.my-7 { margin: 28px 0px; }
.my-8 { margin: 32px 0px; }
.my-9 { margin: 36px 0px; }
.my-10 { margin: 45px 0px; }

.my-3 {
    margin: 0 10px;
}

// For Activate tiny MCE notification disabling
.tox-notifications-container {
    display: none;
}
