//IMP: TO BE UPDATED IN _colors.json WHENEVER CHANDGED",
$app-dark: #000;
$app-background: #1b1d1b;
$app-forground: #fafafa;
$app-warning: rgb(247, 74, 74);
$app-primary: rgb(44, 44, 44);
$hover-primary: rgb(0, 0, 0);
$app-secondary: rgb(85, 85, 85);
$app-shadow: rgb(247, 241, 241);
$app-light: rgb(182, 170, 170);
$forget-button: #18AEED;
$text-color: #FFFFFF;
$button-font: Open Sans;
$inputfield-background:#22303F;
