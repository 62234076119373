$container-bg: rgba(26, 26, 26, 0);;
$font-color: black;
$artist1-bg : #54EEFF;
$artist1-data-bg :  #bff4fa;
$artist2-bg : #4FFFC2;
$artist2-data-bg :  #cdfced;
$artist3-bg :  #fff41d;
$artist3-data-bg :  #fdf77b;
$artist4-bg : #FBB823;
$artist4-data-bg :   #ffd476;

.container {
    width: 800px;
    aspect-ratio: 1.2;
    background-color: $container-bg;
    display: flex;
    flex-direction: row;
    position: relative;
    color: $font-color;
    border-color: black;
    box-shadow: 1px 1px 12px black;
    text-align: center;
    margin: 5px auto;
    transform-origin: top;

    *{
        color: black;
    }

    .artist1-data {
        background-color: $artist1-data-bg;
    }
    .artist2-data {
        background-color: $artist2-data-bg;
    }
    .artist3-data {
        background-color: $artist3-data-bg;
    }
    .artist4-data {
        background-color: $artist4-data-bg;
    }
}

.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
}

.match {
    color: #222;
    font-weight: bolder;
    font-size: 18px;
    margin: 0px 10px;
    span {
        font-size: 24px;
    }
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.locations {
    h4 {
        font-size: 18px;
    }

}

.column {
    width: 50%;
    height: 100%;
}

.row {
    height: 50%;
    display: flex;
    flex-direction: column;
}

.quarter-circle {
    width: 50%;
    height: 60%;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
       transform: scale(1.05);
       z-index: 1000;
    }
    z-index: 1;
}

.quarter-circle-top-left{
    transform-origin: top left;
    margin-bottom: auto;
    background: $artist4-bg;
    border-radius: 0 0 100% 0;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    text-align: right;
}

.quarter-circle-top-right{
    transform-origin: top right;
     margin-left: auto;
    margin-bottom: auto;    
     background: $artist1-bg;
     border-radius: 0 0 0 100%;
     -moz-border-radius: 0 0 0 100%;
     -webkit-border-radius: 0 0 0 100%;
}


.quarter-circle-bottom-left{
    transform-origin: bottom left;
     margin-top: auto;
     background: $artist3-bg;
     border-radius: 0 100% 0 0;
     -moz-border-radius: 0 100% 0 0;
     -webkit-border-radius: 0 100% 0 0;
}

.quarter-circle-bottom-right{
    transform-origin: bottom right;
     margin-left: auto;
     margin-top: auto;
     background: $artist2-bg;
     border-radius: 100% 0 0 0;
     -moz-border-radius: 100% 0 0 0;
     -webkit-border-radius: 100% 0 0 0;
}

.top-data-line {
    height: 45%;
    width: 100%;
}

.bottom-data-line {
    height: 45%;
    width: 100%;
}

.left-top-data-line {
    top: 20%;
    height: 20%;
    width: 50%;
    position: absolute;
}

.left-bottom-data-line {
    bottom: 30%;
    height: 20%;
    width: 50%;
    position: absolute;
}

.right-top-data-line {
    right: 0%;
    top: 20%;
    height: 20%;
    width: 50%;
    position: absolute;
    
}

.right-bottom-data-line {
    right: 0%;
    bottom: 30%;
    height: 20%;
    width: 50%;
    position: absolute;
}

.right-data-line {
    height: 55%;
    width: 45%;
    position: absolute;
}
.matchcontainer-1 {
    height: 55%;
    width: 55%;
    margin-top: 30%;
    margin-left: 25%;
    // overflow: hidden;
}

.matchcontainer-2 {
    height: 55%;
    width: 55%;
    margin-top: 30%;
    margin-left: 5%;
    // overflow: hidden;
}

.matchcontainer-3 {
    height: 55%;
    width: 55%;
    margin-top: 10%;
    margin-left: 5%;
    // overflow: hidden;
}

.matchcontainer-4 {
    height: 55%;
    width: 55%;
    margin-top: 10%;
    margin-left: 25%;
    // overflow: hidden;
}

.flex-match-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: whitesmoke;
    margin-top: 5%;
}

.circle-img{
    width: 60%;
    height: 60%;
    margin-left: 25%;
    transition: all 0.5s;
    img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        border-radius: 50%;
    }
}


.h-data-container {
    display: flex;
    flex-direction: row;
}

.bottom-matching-brands {
    margin-top: 25%;
}

.h-data-container-right {
    display: flex;
    flex-direction: row;
    direction: rtl;
}

.v-data-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    border: white solid 1px;
    align-items: center;
    justify-content: center;
    border-radius: 5%;
    width: 40%;
    height: fit-content;
}

.v-data-container-bottom {
    margin: 10px;
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: white solid 1px;
    border-radius: 5%;
    width: 20%;
    min-height: 15%;
}

.bold-16, .bold-18 {
    font-weight: bold;
    text-overflow: ellipsis;
}

.bold-16 {
    font-size: 16px;
}

.bold-18 {
    font-size: 18px;
}

.bottom-location-box-1, .bottom-location-box-2 {
    position: absolute;
    bottom: 0px;
    width: min-content;
    padding: 0 20px;
}

.bottom-location-box-2 {
    margin-right: 25%;
}

.bottom-location-box-1 {
    margin-left: 25%;
}

.data-container {
    padding: 5px;
    font-size: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.close{
    color: #222;
    font-size: 20px;
    cursor: pointer;
    z-index: 1000;
    width: 100%;
    &:hover {
        color: #F00;
    }
}

.bold {
    font-weight: bold;
}

// .container-2 {
//     width: 80%;
//     height: 250px;

//     .box {
//         height: 100%;
//         width: 100%;
//         overflow: hidden;
//         .artist {
//             width: 100%;
//             height: 100%;
//             border-radius: 50%;
//             position: relative;  
//         }
//         .artist1 {
//             background-color: $artist1-bg;
//             left: 100%;
//             top: 100%;
//             transform: translate(-50%, -50%);
//         }

//         .artist2 {
//             background-color: $artist2-bg;
//             left: -100%;
//             top: 100%;
//             transform: translate(50%, -50%);
//         }
        
//         .artist3 {
//             background-color: $artist4-bg;
//             left: 50%;
//             top: -50%;
//             // transform: translate(-50%, -50%);
//         }
//         .artist4 {
//             background-color: $artist3-bg;
//             left: 100%;
//             top: 100%;
//             transform: translate(-50%, 50%);
//         }
//     }
// }