.concert-table-container {
  max-width: 1200px;
  margin: auto;
}

.submmitedFormsCard {
  padding: 5px 20px;
  margin: 10px;
  transition: all 0.2s;
  width: 98%;
  border-top: 1px solid gray;
  .displayFlex {
    display: flex;
    justify-content: space-between;
    h3{
      color: rgb(250, 229, 184);
    }
    p {
      color: rgb(250, 229, 184);
    }
    div {
      display: flex;
      justify-content: space-evenly;
      *{
        margin: 0 10px;
      }
    }
  }
}

.submmitedFormsCard:hover {
  background-color: rgba(0, 0, 0, 0.438);
}

.headingForms {
  border-top: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}