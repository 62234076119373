@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.landing-page {
  font-family: 'Montserrat', sans-serif;
  background-color: #666;
  height: 89vh;
  overflow: auto;
  background-image: url(https://cdn.wallpapersafari.com/48/11/uRFcp7.jpeg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .first-sec {
    // height: 100%;
    max-width: 800px;

    h4 {
      font-size: 45px;
      font-family: Georgia, 'Times New Roman', Times, serif;
    }

    p {
      width: 60%;
      margin: 0px 20px;
      padding: 5px 20px;
      color: #aaa;
      text-align: center;
      font-size: 18px;
      text-decoration-style: wavy;
    }
  }

  .second-sec {
    background-color: rgba(0, 34, 78, 0.856);
    padding: 0px 20px;
    text-align: left;

    .message-box {
      h4 {
        font-size: 50px;
      }
      p {
        font-size: 18px;
        color: #aaa;
      }
    }
  }

  .third-sec {
    background-color: rgba(48, 0, 179, 0.733);
    padding: 100px 10%;
    text-align: center;
    text-shadow: 0px 0px 2px rgb(0, 0, 0), 0px 0px 6px rgb(36, 35, 35);
    height: 200%;
    max-height: 1300px;
    font-size: 16px;
    h4 {
      font-size: 35px;
    }
    p {
      color: rgb(224, 215, 215);
    }
  }
}


.button-text {
  border: 2px solid rgb(143, 141, 255);
  color: rgb(253, 253, 255);
  padding: 10px;
  border-radius: 5px;

  &:hover {
    border: 2px solid rgb(255, 141, 230);
    font-weight: bold;
    color: #EEE;
  }
}