@import "./colors";
.chart-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.map {
  height: 500px;
  background-color: $app-forground;
  padding: 1rem;
  border-radius: 20px;
  margin-top: 16px;
  box-shadow: 0 0 8px -4px $app-primary;
}

.map .leaflet-container {
  height: 100%;
  border-radius: 12px;
}

.info-flag img {
  width: 100px;
  border-radius: 5px;
}

.info-name {
  font-size: 20px;
  font-weight: bold;
  color: $app-secondary;
}

.info-container {
  width: 150px;
}

.info-flag {
  height: 80px;
  width: 100%;
  background-size: cover;
  border-radius: 8px;
}

.info-confirmed,
.info-recovered,
.info-deaths {
  font-size: 16px;
  margin-top: 5px;
}

.flex{
  display: flex;
}

.custom-legend-dot{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 14px;
}

.legend-txt{
  font-size: 25px;
}

.data-not-found {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-not-found h4 {
  color: $app-warning //rgb(247, 74, 74);
}

.chart-wrapper {
  height: 100%;
  width: 100%;
}
