.header-container {
    box-shadow: 1px -1px 12px rgb(54, 45, 45);
    .header {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px;
        .app-logo {
            .cuttime {
                text-shadow: 1px 1px 2px rgb(0, 255, 242);
                font-weight: bold;
            }
        }
        div {
            color: rgb(253, 250, 250);
            font-size: 15px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.514);
            cursor: pointer;
            a{
                color: #FFF;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 0px;
                }
            }
        }
    }
}

#extra {
    display: none;
}

@media  screen and (max-width: 450px) {
    .header-container {
        div {
            a{
                font-size: 16px;
            }
        }
    }
}

@media  screen and (min-width: 1000px) {
    #hamb {
        display: none;
    }
    #extra {
        display: flex;
    justify-content: space-between;
    align-items: center;
    }
}
